
import {
  DepartmentTypes,
  UserDepartment,
} from "@/store/modules/department/department.types";
import { Pagination } from "@/types/types";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const departmentX = namespace("Department");

@Component({})
export default class DepartmentSelect extends Vue {
  @Prop() public label?: string;
  @Prop() public filled?: boolean;
  @Prop() public outlined?: boolean;
  @Prop() public dense?: boolean;
  @Prop() public disabled?: boolean;
  @Prop() public hideDetails?: boolean;
  @Prop() public rules?: unknown[];
  @Prop() value?: number;

  @departmentX.Action(DepartmentTypes.LOAD_DEPARTMENTS)
  public getDepartments!: (pagination: Pagination) => void;

  @departmentX.State(DepartmentTypes.DEPARTMENTS)
  public departments!: UserDepartment[];

  @departmentX.State(DepartmentTypes.LOADING_DEPARTMENTS_STATE)
  public loadingDepartments!: boolean;

  @Emit("input")
  save(id: string): string {
    return id;
  }

  mounted(): void {
    this.getDepartments({ page: 1, limit: 10 });
  }
}
